/**
 * Type: テンプレート
 * What: 新着情報一覧
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostList from '../components/PostList';
import Pagination from '../components/Pagination';

export default class BlogPage extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { edges: posts } = data.allWordpressPost;
    const POST_URL = data.site.siteMetadata.routingSlug.posts;
    const CATEGORIES_URL = data.site.siteMetadata.routingSlug.categories;

    return (
      <Layout>
        <PostList
          posts={posts}
          title="ニュース｜新着情報一覧"
          description="E・ZO FUKUOKAのイベントやお得な情報、キャンペーンなど最新ニュースをお届けしています。"
          baseUrl={POST_URL}
          categoriesUrl={CATEGORIES_URL}
        />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    );
  }
}

BlogPage.defaultProps = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.string,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
};

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        routingSlug {
          posts
          categories
        }
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`;
